import React from "react";
import Popup from "reactjs-popup";
import SimpleSlider from "../SimpleSlider/SimpleSlider";
import "./PopupModal.scss";
import Cookies from "universal-cookie";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";

const PopupModal = () => {
  const cookies = new Cookies();
  const [newCookie, setNewCookie] = useState("");
  const access_token_skip = cookies.get("access_token_skip");

  useEffect(() => {
    setNewCookie(access_token_skip);
  }, [access_token_skip]);

  return newCookie === undefined ? (
    <Popup modal nested open={true}>
      {(close) => (
        <div className="modal">
          <div className="content">
            <SimpleSlider handleClick={() => close()} />
          </div>
          <div className="actions">
            <button
              className="button"
              onClick={() => {
                let uuid = uuidv4();
                cookies.set("access_token_skip", uuid, {
                  path: "/",
                  expires: new Date(Date.now() + 8.64e7),
                });
                close();
                // setNewCookie(uuid);
              }}
            >
              Skip
            </button>
          </div>
        </div>
      )}
    </Popup>
  ) : null;
};

export default PopupModal;
