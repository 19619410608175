export const SET_ORDER_PANEL_COUNT = "set_order_panel_count";
export const SET_ORDER_ROOF_TYPE = "set_order_roof_type";
export const SET_ORDER_ROOF_INFO = "set_order_roof_info";
export const SET_CUSTOMER_INFO = "set_customer_info";
export const SET_PANEL_TYPE = "set_panel_type";
export const SET_ORDER_INFO = "set_order_info";
export const SET_ROOF_INFORMATION = "set_roof_information";
export const SET_SFNumber = "set_sf_numer";
export const SET_COMMENT = "set_comment";
export const SET_ORDER = "set_order";
export const SET_ENOVE = "set_enova";
export const SET_PARTNER_ID = "set_partner_id";
