import {
  SET_ADDRESS,
  SET_ADDRESS_LATLNG,
  SET_CHECKED_ADDRESS,
  SET_FINAL_ADDRESS,
  SET_MUNICIPALITY,
} from "./types";

export function setAddress(address) {
  return {
    type: SET_ADDRESS,
    address,
  };
}

export function setCheckedAddress(checkedAddress) {
  return {
    type: SET_CHECKED_ADDRESS,
    checkedAddress,
  };
}

export function setFinalAddress(finalAddress) {
  return {
    type: SET_FINAL_ADDRESS,
    finalAddress,
  };
}

export function setMunicipality(municipality) {
  return {
    type: SET_MUNICIPALITY,
    municipality,
  };
}

// export function setAddressLatlng(latlng) {
//   return {
//     type: SET_ADDRESS_LATLNG,
//     latlng,
//   };
// }
