import "./RoofColor.scss";

const RoofColor = () => {
  return (
    <div className="roof-color">
      <div className="roof-color-section">
        <div className="roof-color-title">
          Takflatens egnethet for solenergi
        </div>
        <div className="roof-color-container">
          <div className="roof-colors"></div>
          <div className="roof-color-labels">
            <p>Lite egnet</p>
            <p>Velegnet</p>
            <p>Svært egnet</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoofColor;
