export const Buildingicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="31"
      viewBox="0 0 27 31"
      fill="none"
    >
      <path
        d="M11.25 7.01953C11.25 6.66797 10.8984 6.31641 10.5469 6.31641H8.20312C7.79297 6.31641 7.5 6.66797 7.5 7.01953V9.36328C7.5 9.77344 7.79297 10.0664 8.20312 10.0664H10.5469C10.8984 10.0664 11.25 9.77344 11.25 9.36328V7.01953ZM18.0469 6.31641H15.7031C15.293 6.31641 15 6.66797 15 7.01953V9.36328C15 9.77344 15.293 10.0664 15.7031 10.0664H18.0469C18.3984 10.0664 18.75 9.77344 18.75 9.36328V7.01953C18.75 6.66797 18.3984 6.31641 18.0469 6.31641ZM10.5469 11.9414H8.20312C7.79297 11.9414 7.5 12.293 7.5 12.6445V14.9883C7.5 15.3984 7.79297 15.6914 8.20312 15.6914H10.5469C10.8984 15.6914 11.25 15.3984 11.25 14.9883V12.6445C11.25 12.293 10.8984 11.9414 10.5469 11.9414ZM18.0469 11.9414H15.7031C15.293 11.9414 15 12.293 15 12.6445V14.9883C15 15.3984 15.293 15.6914 15.7031 15.6914H18.0469C18.3984 15.6914 18.75 15.3984 18.75 14.9883V12.6445C18.75 12.293 18.3984 11.9414 18.0469 11.9414ZM10.5469 17.5664H8.20312C7.79297 17.5664 7.5 17.918 7.5 18.2695V20.6133C7.5 21.0234 7.79297 21.3164 8.20312 21.3164H10.5469C10.8984 21.3164 11.25 21.0234 11.25 20.6133V18.2695C11.25 17.918 10.8984 17.5664 10.5469 17.5664ZM18.0469 17.5664H15.7031C15.293 17.5664 15 17.918 15 18.2695V20.6133C15 21.0234 15.293 21.3164 15.7031 21.3164H18.0469C18.3984 21.3164 18.75 21.0234 18.75 20.6133V18.2695C18.75 17.918 18.3984 17.5664 18.0469 17.5664ZM26.25 29.5781C26.25 29.2266 25.8984 28.875 25.5469 28.875H24.375V2.15625C24.375 1.39453 23.7305 0.75 22.9688 0.75H3.28125C2.46094 0.75 1.875 1.39453 1.875 2.15625V28.875H0.703125C0.292969 28.875 0 29.2266 0 29.5781V30.75H26.25V29.5781ZM22.5 28.875H15V23.8945C15 23.543 14.6484 23.1914 14.2969 23.1914H11.9531C11.543 23.1914 11.25 23.543 11.25 23.8945V28.875H3.75V2.625H22.5V28.875Z"
        fill="#5F6368"
      />
    </svg>
  );
};
