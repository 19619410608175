export const Lightbulbicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="16"
      viewBox="0 0 11 16"
      fill="none"
    >
      <path
        d="M5.15625 3.21875C3.60352 3.21875 2.34375 4.50781 2.34375 6.03125C2.34375 6.29492 2.54883 6.5 2.8125 6.5C3.04688 6.5 3.28125 6.29492 3.28125 6.03125C3.28125 5.00586 4.10156 4.15625 5.15625 4.15625C5.39062 4.15625 5.625 3.95117 5.625 3.6875C5.625 3.45312 5.39062 3.21875 5.15625 3.21875ZM2.8125 14.3516C2.8125 14.4395 2.8125 14.5273 2.87109 14.6152L3.60352 15.6699C3.69141 15.8164 3.83789 15.875 3.98438 15.875H6.29883C6.44531 15.875 6.5918 15.8164 6.67969 15.6699L7.41211 14.6152C7.44141 14.5273 7.4707 14.4395 7.4707 14.3516V13.0625H2.8125V14.3516ZM5.15625 0.875C2.13867 0.875 0 3.30664 0 6.03125C0 7.34961 0.46875 8.52148 1.25977 9.42969C1.75781 9.98633 2.51953 11.1582 2.7832 12.125H4.18945C4.18945 12.0078 4.18945 11.8613 4.13086 11.7148C3.98438 11.1875 3.48633 9.83984 2.31445 8.52148C1.72852 7.81836 1.40625 6.93945 1.40625 6.03125C1.37695 3.89258 3.13477 2.28125 5.15625 2.28125C7.20703 2.28125 8.90625 3.98047 8.90625 6.03125C8.90625 6.93945 8.55469 7.81836 7.96875 8.52148C6.82617 9.81055 6.29883 11.1875 6.15234 11.7148C6.09375 11.8613 6.09375 11.9785 6.09375 12.125H7.5C7.76367 11.1582 8.52539 9.98633 9.02344 9.42969C9.81445 8.52148 10.3125 7.34961 10.3125 6.03125C10.3125 3.18945 7.99805 0.875 5.15625 0.875Z"
        fill="#F67736"
      />
    </svg>
  );
};
