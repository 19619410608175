import { combineReducers } from "redux";
import AddressReducer from "./Address/reducer";
import RoofReducer from "./Roof/reducer";
import ShowReducer from "./Show/reducer";
import OrderReducer from "./Order/reducer";

export default combineReducers({
  Address: AddressReducer,
  Roof: RoofReducer,
  Show: ShowReducer,
  Order: OrderReducer,
});
