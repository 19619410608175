export const Houseicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="21"
      viewBox="0 0 24 21"
      fill="none"
    >
      <path
        d="M23.2656 9.875L21 7.84375V2.6875C21 2.17969 20.5703 1.75 20.0625 1.75H16.3125C15.7656 1.75 15.375 2.17969 15.375 2.6875V2.80469L13.2656 0.929688C12.9922 0.65625 12.6406 0.5 12.25 0.5C11.8203 0.5 11.4688 0.65625 11.1953 0.929688L1.19531 9.875C1.07812 9.99219 0.960938 10.1875 0.960938 10.3438C0.960938 10.5391 1.03906 10.6562 1.15625 10.7734L1.54688 11.2422C1.66406 11.3594 1.82031 11.4375 2.01562 11.4375C2.17188 11.4375 2.32812 11.3984 2.44531 11.2812L3.46094 10.3438V18.9375C3.46094 19.8359 4.16406 20.5 5.02344 20.5H19.4375C20.2969 20.5 21 19.8359 21 18.9375V10.3438L22.0156 11.2812C22.1328 11.3984 22.2891 11.4375 22.4453 11.4375C22.6406 11.4375 22.7969 11.3594 22.9141 11.2422L23.3438 10.7734C23.4219 10.6562 23.5 10.5391 23.5 10.3438C23.5 10.1875 23.3828 9.99219 23.2656 9.875ZM19.125 18.625H5.375V8.66406L12.25 2.49219L19.125 8.66406V18.625ZM19.125 6.16406L17.25 4.48438V3.625H19.125V6.16406ZM9.75 8.625V12.375C9.75 12.7266 10.0234 13 10.375 13H14.125C14.4375 13 14.75 12.7266 14.75 12.375V8.625C14.75 8.3125 14.4375 8 14.125 8H10.375C10.0234 8 9.75 8.3125 9.75 8.625Z"
        fill="#F67736"
      />
    </svg>
  );
};
