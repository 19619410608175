export const Phoneicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        d="M23.4375 0.625H2.8125C1.23047 0.625 0 1.91406 0 3.4375V24.0625C0 25.6445 1.23047 26.875 2.8125 26.875H23.4375C24.9609 26.875 26.25 25.6445 26.25 24.0625V3.4375C26.25 1.91406 24.9609 0.625 23.4375 0.625ZM24.375 24.0625C24.375 24.5898 23.9062 25 23.4375 25H2.8125C2.28516 25 1.875 24.5898 1.875 24.0625V3.4375C1.875 2.96875 2.28516 2.5 2.8125 2.5H23.4375C23.9062 2.5 24.375 2.96875 24.375 3.4375V24.0625ZM21.1523 5.25391L17.6953 4.43359C17.5781 4.43359 17.4609 4.43359 17.2852 4.43359C16.582 4.43359 15.9961 4.84375 15.7031 5.42969L14.1211 9.12109C13.8281 9.82422 14.0625 10.7031 14.6484 11.1719L16.0547 12.3438C15 14.1602 13.4766 15.6836 11.6602 16.7383L10.4883 15.332C10.0195 14.7461 9.14062 14.5117 8.4375 14.8047L4.74609 16.3867C3.98438 16.7383 3.57422 17.5586 3.75 18.3789L4.57031 21.8359C4.74609 22.5977 5.44922 23.125 6.26953 23.125C15.2344 23.125 22.5 15.918 22.5 6.89453C22.5 6.13281 21.9141 5.42969 21.1523 5.25391ZM6.32812 21.25L5.625 18.0859L9.08203 16.6211L11.1328 19.082C14.7656 17.3828 16.6406 15.5078 18.3984 11.8164L15.9375 9.76562L17.4023 6.30859L20.5664 7.01172C20.5078 14.8633 14.1797 21.1914 6.32812 21.25Z"
        fill="#5F6368"
      />
    </svg>
  );
};
