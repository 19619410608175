import { SET_SHOW, SET_SHOW_COOKIE } from "./types";
import ShowState from "./state";

const Reducer = (state = ShowState, options) => {
  switch (options.type) {
    case SET_SHOW:
      state.show = options.show;
      break;
    case SET_SHOW_COOKIE:
      state.showCookie = options.showCookie;
      break;
    default:
      break;
  }

  return { ...state };
};

export default Reducer;
