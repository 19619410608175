import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOrderPanelCount } from "../../store/Order/action";
import "./ProgressBar.scss";
import "rsuite/dist/rsuite.css";
import { Slider } from "rsuite";
import { setRoofInfo } from "../../store/Roof/action";

const ProgressBar = () => {
  const dispatch = useDispatch();
  const roofInfo = useSelector((state) => state.Roof.roofInfo);
  const p = useSelector((state) => state.Order.panelCount);
  const [value, setValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [increment, setIncrement]=useState(null)
  

  useEffect(() => {
    if (roofInfo && roofInfo.paneler) {
      const antallPanelers = roofInfo.paneler.map(
        (panel) => panel.antallPaneler
      );

      const differences = antallPanelers.slice(1).map((val, index) => val - antallPanelers[index]);
      const commonDiff = differences.reduce(
        (acc, diff) => (acc === diff ? acc : null),
        differences[0]
      );

      setIncrement(commonDiff);
    }
  }, [roofInfo]); 
  
  useEffect(() => {
    if (Object.keys(roofInfo).length > 0) {
      let found;
      if (p === "") {
        found = roofInfo.paneler.find(
          (element) => element.pris * 1.25 >= 190000
        );
      } else {
        found = roofInfo.paneler.find((element) => element.antallPaneler === p);
      }

      let panelCount;
      let max = roofInfo.paneler[roofInfo.paneler.length - 1].antallPaneler;

      if (found !== undefined) {
        panelCount = found.antallPaneler;
      } else {
        panelCount =
          roofInfo.paneler[roofInfo.paneler.length - 1].antallPaneler;
      }
      setMaxValue(max);
      if (max < panelCount) {
        setValue(max);
        setOrderPanelCount(max);
      } else {
        setValue(panelCount);
        setOrderPanelCount(panelCount);
      }
    } 
    return () => {
      dispatch(setRoofInfo(""));
    }
  }, []);

  return (
    <>
      {value > 0 ? (
        <div
          style={{
            display: "block",
            width: "50%",
          }}
          className="progressbar-container"
        >
          <Slider
            max={maxValue}
            min={roofInfo.paneler[0].antallPaneler}
            step={increment}
            defaultValue={value}
            onChange={(value) => {
              setValue(value);
              dispatch(setOrderPanelCount(value));
            }}
            progress
          />
          <div className="panel-quantity">Antall Paneler: {value}</div>
        </div>
      ) : null}
    </>
  );
};

export default ProgressBar;
