export const Phoneiconmin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M14.9121 0.375H2.53711C1.58789 0.375 0.849609 1.14844 0.849609 2.0625V14.4375C0.849609 15.3867 1.58789 16.125 2.53711 16.125H14.9121C15.8262 16.125 16.5996 15.3867 16.5996 14.4375V2.0625C16.5996 1.14844 15.8262 0.375 14.9121 0.375ZM15.4746 14.4375C15.4746 14.7539 15.1934 15 14.9121 15H2.53711C2.2207 15 1.97461 14.7539 1.97461 14.4375V2.0625C1.97461 1.78125 2.2207 1.5 2.53711 1.5H14.9121C15.1934 1.5 15.4746 1.78125 15.4746 2.0625V14.4375ZM13.541 3.15234L11.4668 2.66016C11.3965 2.66016 11.3262 2.66016 11.2207 2.66016C10.7988 2.66016 10.4473 2.90625 10.2715 3.25781L9.32227 5.47266C9.14648 5.89453 9.28711 6.42188 9.63867 6.70312L10.4824 7.40625C9.84961 8.49609 8.93555 9.41016 7.8457 10.043L7.14258 9.19922C6.86133 8.84766 6.33398 8.70703 5.91211 8.88281L3.69727 9.83203C3.24023 10.043 2.99414 10.5352 3.09961 11.0273L3.5918 13.1016C3.69727 13.5586 4.11914 13.875 4.61133 13.875C9.99023 13.875 14.3496 9.55078 14.3496 4.13672C14.3496 3.67969 13.998 3.25781 13.541 3.15234ZM4.64648 12.75L4.22461 10.8516L6.29883 9.97266L7.5293 11.4492C9.70898 10.4297 10.834 9.30469 11.8887 7.08984L10.4121 5.85938L11.291 3.78516L13.1895 4.20703C13.1543 8.91797 9.35742 12.7148 4.64648 12.75Z"
        fill="#C9C5C5"
      />
    </svg>
  );
};
