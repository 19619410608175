export const Envelopeiconmin = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="14"
      viewBox="0 0 19 14"
      fill="none"
    >
      <path
        d="M17.1621 0.5H2.53711C1.58789 0.5 0.849609 1.27344 0.849609 2.1875V12.3125C0.849609 13.2617 1.58789 14 2.53711 14H17.1621C18.0762 14 18.8496 13.2617 18.8496 12.3125V2.1875C18.8496 1.27344 18.0762 0.5 17.1621 0.5ZM2.53711 1.625H17.1621C17.4434 1.625 17.7246 1.90625 17.7246 2.1875V3.66406C16.9512 4.29688 15.8262 5.21094 12.416 7.91797C11.8184 8.41016 10.6582 9.53516 9.84961 9.5C9.00586 9.53516 7.8457 8.41016 7.24805 7.91797C3.83789 5.21094 2.71289 4.29688 1.97461 3.66406V2.1875C1.97461 1.90625 2.2207 1.625 2.53711 1.625ZM17.1621 12.875H2.53711C2.2207 12.875 1.97461 12.6289 1.97461 12.3125V5.10547C2.74805 5.77344 4.01367 6.79297 6.54492 8.79688C7.2832 9.39453 8.54883 10.6602 9.84961 10.625C11.1152 10.6602 12.3809 9.39453 13.1191 8.79688C15.6504 6.79297 16.916 5.77344 17.7246 5.10547V12.3125C17.7246 12.6289 17.4434 12.875 17.1621 12.875Z"
        fill="#C9C5C5"
      />
    </svg>
  );
};
