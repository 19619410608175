export const Arrowdownicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="11"
      viewBox="0 0 19 11"
      fill="none"
    >
      <path
        d="M1.81641 0C0.761719 0 0.234375 1.28906 0.996094 2.05078L8.49609 9.55078C8.96484 10.0195 9.72656 10.0195 10.1953 9.55078L17.6953 2.05078C18.457 1.28906 17.9297 0 16.875 0H1.81641Z"
        fill="#5F6368"
      />
    </svg>
  );
};
