import {
  SET_ADDRESS,
  SET_ADDRESS_LATLNG,
  SET_CHECKED_ADDRESS,
  SET_FINAL_ADDRESS,
  SET_MUNICIPALITY,
} from "./types";
import AddressState from "./state";

const Reducer = (state = AddressState, options) => {
  switch (options.type) {
    case SET_ADDRESS:
      state.address = options.address;
      break;
    case SET_CHECKED_ADDRESS:
      state.checkedAddress = options.checkedAddress;
      break;
    case SET_FINAL_ADDRESS:
      state.finalAddress = options.finalAddress;
      break;
    // case SET_ADDRESS_LATLNG:
    //   state.latlng = options.latlng;
    //   break;
    case SET_MUNICIPALITY:
      state.municipality = options.municipality;
      break;
    default:
      break;
  }

  return { ...state };
};

export default Reducer;
