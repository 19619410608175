export const Housegreyicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
    >
      <path
        d="M20.8887 8.75781L18.8496 6.96484V1.9375C18.8496 1.65625 18.5684 1.375 18.2871 1.375H14.9121C14.5957 1.375 14.3496 1.65625 14.3496 1.9375V3.02734L11.3262 0.390625C11.2207 0.320312 11.1152 0.285156 10.9746 0.285156C10.7988 0.285156 10.6934 0.320312 10.5879 0.390625L1.02539 8.75781C0.919922 8.86328 0.814453 9.03906 0.814453 9.21484C0.814453 9.49609 1.0957 9.77734 1.37695 9.77734C1.55273 9.77734 1.6582 9.70703 1.76367 9.63672L3.09961 8.47656V16.5625C3.09961 17.5117 3.83789 18.25 4.78711 18.25H17.1621C18.0762 18.25 18.8145 17.5117 18.8496 16.5625V8.47656L20.1504 9.63672C20.2559 9.70703 20.3613 9.74219 20.502 9.74219C20.8184 9.74219 21.0645 9.49609 21.0645 9.17969C21.0645 9.03906 20.9941 8.86328 20.8887 8.75781ZM17.7246 16.5625C17.7246 16.8789 17.4434 17.125 17.1621 17.125H4.78711C4.4707 17.125 4.22461 16.8789 4.22461 16.5625V7.49219L10.9746 1.58594L17.7246 7.49219V16.5625ZM17.7246 5.98047L15.4746 4.01172V2.5H17.7246V5.98047ZM8.16211 7.94922V11.7109C8.16211 12.2383 8.54883 12.625 9.07617 12.625H12.8379C13.3652 12.625 13.752 12.2383 13.7871 11.7109V7.94922C13.752 7.42188 13.3652 7.03516 12.8379 7H9.07617C8.54883 7.03516 8.16211 7.42188 8.16211 7.94922ZM9.28711 8.125H12.6621V11.5H9.28711V8.125Z"
        fill="#C9C5C5"
      />
    </svg>
  );
};
