export function getPartnerId(url) {
  let partners = [
    { id: 10021, name: "finnas" },
    { id: 10011, name: "siggerud" },
    { id: 10024, name: "elbil" },
  ];

  if (url.match(/\d/)) {
    console.log("url", url.indexOf("nolimits=1"));
    if (url.indexOf("nolimits=1") === -1) {
      return 10005;
    }
  } else {
    let partnerName = url.split("=");
    let partnerId = partners.find((el) => el.name === partnerName[1]);
    if (partnerId !== undefined) {
      return partnerId.id;
    }
  }
}
