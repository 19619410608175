export async function getAddressFromLatLng(latlng) {
  var query = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latlng.lat}&lon=${latlng.lng}`;

  const apiResult = await fetch(query, {
    headers: {
      Accept: "application/json",
    },
    method: "GET",
  });

  if (apiResult.status === 200) {
    return await getResponse(apiResult);
  }

  return null;
}

async function getResponse(apiResult) {
  const json = await apiResult.json();
  let municipality = json.address.municipality;
  return municipality;
}
