export const Searchicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M19.8438 18.8203L15.1172 14.0938C15 14.0156 14.8828 13.9375 14.7656 13.9375H14.2578C15.4688 12.5312 16.25 10.6562 16.25 8.625C16.25 4.17188 12.5781 0.5 8.125 0.5C3.63281 0.5 0 4.17188 0 8.625C0 13.1172 3.63281 16.75 8.125 16.75C10.1562 16.75 11.9922 16.0078 13.4375 14.7969V15.3047C13.4375 15.4219 13.4766 15.5391 13.5547 15.6562L18.2812 20.3828C18.4766 20.5781 18.7891 20.5781 18.9453 20.3828L19.8438 19.4844C20.0391 19.3281 20.0391 19.0156 19.8438 18.8203ZM8.125 14.875C4.64844 14.875 1.875 12.1016 1.875 8.625C1.875 5.1875 4.64844 2.375 8.125 2.375C11.5625 2.375 14.375 5.1875 14.375 8.625C14.375 12.1016 11.5625 14.875 8.125 14.875Z"
        fill="#F67736"
      />
    </svg>
  );
};
