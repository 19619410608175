export const Arrowicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
    >
      <path
        d="M9.84766 20.8281C10.1406 21.1211 10.5508 21.1211 10.8438 20.8281L19.5156 12.2148C19.8086 11.9805 19.8086 11.5117 19.5156 11.2188L18.3438 10.1055C18.1094 9.8125 17.6406 9.8125 17.3477 10.1055L10.375 17.0195L3.34375 10.1055C3.05078 9.8125 2.64062 9.8125 2.34766 10.1055L1.17578 11.2188C0.882812 11.5117 0.882812 11.9805 1.17578 12.2148L9.84766 20.8281ZM10.8438 11.4531L19.5156 2.83984C19.8086 2.60547 19.8086 2.13672 19.5156 1.84375L18.3438 0.730469C18.1094 0.4375 17.6406 0.4375 17.3477 0.730469L10.375 7.64453L3.34375 0.730469C3.05078 0.4375 2.64062 0.4375 2.34766 0.730469L1.17578 1.84375C0.882812 2.13672 0.882812 2.60547 1.17578 2.83984L9.84766 11.4531C10.1406 11.7461 10.5508 11.7461 10.8438 11.4531Z"
        fill="#FD7B1D"
      />
    </svg>
  );
};
