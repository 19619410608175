import "./Footer.scss";
import { FooterLogoicon } from "../icons/FooterLogoicon";

const Footer = () => {
  let year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="container">
        <div className="footer-container">
          <div className="footer-left">© {year} Solcellespesialisten AS</div>
          <div className="footer-right">
            <span>Drevet av </span>
            <FooterLogoicon />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
