export const Globeicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M14.5312 0.21875C6.50391 0.21875 0 6.72266 0 14.75C0 22.7773 6.50391 29.2812 14.5312 29.2812C22.5586 29.2812 29.0625 22.7773 29.0625 14.75C29.0625 6.72266 22.5586 0.21875 14.5312 0.21875ZM25.8398 9.125H20.9766C20.4492 6.54688 19.5703 4.32031 18.457 2.73828C21.6797 3.85156 24.3164 6.13672 25.8398 9.125ZM19.6875 14.75C19.6875 16.0977 19.5703 17.3867 19.3945 18.5H9.60938C9.43359 17.3867 9.375 16.0977 9.375 14.75C9.375 13.4609 9.43359 12.1719 9.60938 11H19.3945C19.5703 12.1719 19.6875 13.4609 19.6875 14.75ZM14.5312 2.09375C16.0547 2.09375 18.1055 4.73047 19.1016 9.125H9.90234C10.8984 4.73047 12.9492 2.09375 14.5312 2.09375ZM10.5469 2.73828C9.43359 4.32031 8.55469 6.54688 8.02734 9.125H3.16406C4.6875 6.13672 7.32422 3.85156 10.5469 2.73828ZM1.875 14.75C1.875 13.4609 2.05078 12.2305 2.40234 11H7.73438C7.55859 12.2305 7.5 13.4609 7.5 14.75C7.5 16.0977 7.55859 17.3281 7.73438 18.5H2.40234C2.05078 17.3281 1.875 16.0977 1.875 14.75ZM3.16406 20.375H8.02734C8.55469 23.0117 9.43359 25.2383 10.5469 26.8203C7.32422 25.707 4.6875 23.4219 3.16406 20.375ZM14.5312 27.4062C12.9492 27.4062 10.8984 24.8281 9.90234 20.375H19.1016C18.1055 24.8281 16.0547 27.4062 14.5312 27.4062ZM18.457 26.8203C19.5703 25.2383 20.4492 23.0117 20.9766 20.375H25.8398C24.3164 23.4219 21.6797 25.707 18.457 26.8203ZM21.2695 18.5C21.4453 17.3281 21.5625 16.0977 21.5625 14.75C21.5625 13.4609 21.4453 12.2305 21.2695 11H26.6016C26.9531 12.2305 27.1875 13.4609 27.1875 14.75C27.1875 16.0977 26.9531 17.3281 26.6016 18.5H21.2695Z"
        fill="#5F6368"
      />
    </svg>
  );
};
