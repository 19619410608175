import ReactTooltip from "react-tooltip";
import "./TooltipComponent.scss";

export const TooltipComponentBack = () => {
  return (
    <ReactTooltip
      id="main"
      place="bottom"
      effect="solid"
      multiline={true}
      className="tooltip"
    />
  );
};

export const TooltipComponentClose = () => {
  return (
    <ReactTooltip
      id="mains"
      place="bottom"
      effect="solid"
      multiline={true}
      className="tooltip"
    />
  );
};

export const TooltipComponentForward = () => {
  return (
    <ReactTooltip
      id="go-to-step2"
      place="bottom"
      effect="solid"
      multiline={true}
      className="tooltip"
    />
  );
};
