import "./RoofOrder.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { setShow } from "../../store/Show/action";
import { Closeicon } from "../icons/Closeicon";
import { Leftarrowicon } from "../icons/Leftarrowicon";
import { useForm } from "react-hook-form";
import { Usericon } from "../icons/Usericon";
import { Addresscardicon } from "../icons/Addresscardicon";
import { Housegreyicon } from "../icons/Housegreyicon";
import { Buildinggreyicon } from "../icons/Buildinggreyicon";
import { Cityicon } from "../icons/Cityicon";
import { Envelopeiconmin } from "../../components/icons/Envelopeiconmin";
import { Phoneiconmin } from "../../components/icons/Phoneiconmin";
import RoofPanel from "../RoofPanel/RoofPanel";
import { setRoofInfo } from "../../store/Roof/action";
import { TooltipComponentBack } from "../TooltipComponent/TooltipComponent";
import { TooltipComponentClose } from "../TooltipComponent/TooltipComponent";
import { sendOrderData, setCustomerInfo } from "../../store/Order/action";
import { Birthdaycakeicon } from "../icons/Birthdaycakeicon";
import { useEffect } from "react";
import { useState } from "react";
import moment from 'moment';

const RoofOrder = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let roofInfo = useSelector((state) => state.Roof.roofInfo);
  let orderInfo = useSelector((state) => state.Order);
  let address = useSelector((state) => state.Address.address);
  let finalAddress = useSelector((state) => state.Address.finalAddress);
  let municipalityAddress = useSelector((state) => state.Address.municipality);
  let [municipality, setMunicipality] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    if (municipalityAddress !== undefined) {
      setMunicipality(municipalityAddress);
    } else if (Object.keys(address).length > 0) {
      let addressName = address.text.split(",");
      let municipal = addressName[addressName.length - 1];
      setMunicipality(municipal);
    }
  }, [address, municipalityAddress]);

  return (
    <div className="roof-order-section">
      <div className="roof-order-header">
        <div>
          <button
            data-for="main"
            data-tip="Tilbudsskjema"
            onClick={() => {
              dispatch(setShow("step2"));
              reset();
            }}
          >
            <Leftarrowicon />
          </button>
          <span>Tilbudsskjema</span>
          <TooltipComponentBack />
        </div>
        <div>
          <span>Trinn 3 av 3</span>
        </div>
        <div>
          <button
            data-for="mains"
            data-tip="Avbryt"
            onClick={() => {
              navigate("/");
              dispatch(setShow("step2"));
              dispatch(setRoofInfo(""));
            }}
          >
            <Closeicon />
          </button>
          <span>Avbryt</span>
          <TooltipComponentClose />
        </div>
      </div>

      {Object.keys(roofInfo).length > 0 && !roofInfo.message ? (
        <>
          <div className="roof-order-container">
            <div>
              <div className="roof-order-form">
                <div className="roof-order-form-container">
                  <form
                    onSubmit={handleSubmit((customerData) => {
                      let newOrderInfo = {
                        ...orderInfo,
                        kwh: orderInfo?.orderInfo?.produksjon[12].kWh,
                        customerInfo: customerData,
                      };
                      if (!isDisabled) {
                        dispatch(setCustomerInfo(customerData));
                        dispatch(sendOrderData(newOrderInfo));
                      }
                      setIsDisabled(true);
                    })}
                  >
                    <div className="form-inputs-first">
                      <div>
                        <label htmlFor="name">Navn</label>
                        <div>
                          <input {...register("name", { required: true })} />
                          <Usericon />
                          {errors.name && (
                            <p className="danger-text">Navn er påkrevd.</p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label htmlFor="surname">Etternavn</label>
                        <div>
                          <input {...register("surname", { required: true })} />
                          <Usericon />
                          {errors.surname && (
                            <p className="danger-text">Etternavn er påkrevd.</p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label htmlFor="email">E-post</label>
                        <div className="input-icons">
                          <input
                            type="email"
                            {...register("email", { required: true })}
                          />
                          <Envelopeiconmin />
                          {errors.email && (
                            <p className="danger-text">E-post er påkrevd.</p>
                          )}
                        </div>
                      </div>
                      <div>
                        <label htmlFor="phone">Telefon</label>
                        <div className="input-icons">
                          <input
                            type="number"
                            {...register("phone", { required: true })}
                          />
                          <Phoneiconmin />
                          {errors.phone && (
                            <p className="danger-text">Telefon kreves.</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-inputs-second">
                      <div>
                        <label htmlFor="address">Adresse</label>
                        <div>
                          <input
                            {...register("address")}
                            value={roofInfo.ruteplanlegger.til.split(",")[0]}
                            readOnly
                          />
                          <Addresscardicon />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="postalCode">Postnummer</label>
                        <div className="input-icons">
                          <input
                            type="text"
                            value={roofInfo.adresse.postNummer}
                            {...register("postalCode")}
                            readOnly
                          />
                          <Housegreyicon />
                        </div>
                      </div>

                      <div>
                        <label htmlFor="municipality">Kommune</label>
                        <div>
                          <input
                            {...register("municipality")}
                            value={municipality}
                            readOnly
                          />
                          <Cityicon />
                        </div>
                      </div>
                      <div>
                        <label htmlFor="by">By</label>
                        <div className="input-icons">
                          <input
                            type="text"
                            value={roofInfo.adresse.postSted}
                            {...register("by")}
                            readOnly
                          />
                          <Buildinggreyicon />
                        </div>
                      </div>
                    </div>
                    <div className="form-button">
                      <button>Be om tilbud</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <RoofPanel
              data={{
                lcoe: orderInfo.roofOrderInfo.lcoe,
                anProd: orderInfo.roofOrderInfo.anProd,
              }}
            />
            <div className="roof-order-address-info">
              <p>{roofInfo.ruteplanlegger.til.split(",")[0]}</p>
              <p>
                {roofInfo.adresse.postNummer} {roofInfo.adresse.postSted}
              </p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default RoofOrder;
