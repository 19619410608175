export default {
  panelCount: "",
  orderRoofType: "",
  roofOrderInfo: [],
  customerInfo: {},
  panelType: "",
  orderInfo: {},
  roofInformation: {},
  SFNumber: "",
  comment: "",
  order: {},
  enovaSupport: "",
  partnerId: 10004,
};
