import "./Offer.scss";
import { motion } from "framer-motion";
import RoofTypes from "../../components/RoofTypes/RoofTypes";
import RoofPrice from "../../components/RoofPrice/RoofPrice";
import { useSelector } from "react-redux";
import RoofOrder from "../../components/RoofOrder/RoofOrder";
import CompleteOrder from "../../components/CompleteOrder/CompleteOrder";
import getMotionVariants from "../../api/getMotionVariants";

const Offer = () => {
  const show = useSelector((state) => state.Show.show);
  return (
    // <motion.div
    //   variants={getMotionVariants()}
    //   initial="initial"
    //   animate="animate"
    //   transition={{ duration: 1, ease: "easeInOut" }}
    // >
      <div className="offer-page-section">
        <div className="offer-page-container">
          <div
            style={
              show === "step1" ? { display: "block" } : { display: "none" }
            }
          >
            <RoofTypes />
          </div>
          <div
            style={
              show === "step2" ? { display: "block" } : { display: "none" }
            }
          >
            <RoofPrice />
          </div>
          <div
            style={
              show === "step3" ? { display: "block" } : { display: "none" }
            }
          >
            <RoofOrder />
          </div>
          <div
            style={
              show === "step4" ? { display: "block" } : { display: "none" }
            }
          >
            <CompleteOrder />
          </div>
        </div>
      </div>
    // </motion.div>
  );
};

export default Offer;
