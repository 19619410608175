import {
  LayersControl,
  MapContainer,
  Marker,
  Polygon,
  TileLayer,
  useMap,
  WMSTileLayer,
} from "react-leaflet";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { config } from "../../api/config";
import { getMapCoordinates } from "../../api/getCoordinatesApi";
import {
  // setAddressLatlng,
  setCheckedAddress,
  setMunicipality,
} from "../../store/Address/action";
import { useNavigate } from "react-router";
import { setShow } from "../../store/Show/action";
import { getAddressFromLatLng } from "../../api/getAddressFromLatLng";

const OrderMap = () => {
  const [latlng, setlatlng] = useState({
    lat: 63.79457628473434,
    lng: 11.486627316328393,
  });
  const [markerLatlng, setMarkerLatlng] = useState({
    lat: "",
    lng: "",
  });
  const [addressName, setAddressName] = useState(
    "M B Landstads gate 4, Verdal"
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [purpleOptions, setPurpleOptions] = useState({ color: "purple" });

  const dispatch = useDispatch();
  let navigate = useNavigate();

  let address = useSelector((state) => state.Address.address);
  let getNoLimits = sessionStorage.getItem("noLimits");

  useEffect(() => {
    if (Object.keys(address).length > 0) {
      setlatlng(address.latlng);
      setMarkerLatlng(address.latlng);
      setAddressName(address.text);
    }
  }, [address]);

  useEffect(() => {
    if (isDisabled) {
      if (getNoLimits === "true") {
        navigate("/offer/?nolimits=1");
      } else {
        navigate("/offer");
      }
    }
  }, [isDisabled]);

  const props = {
    layers: "takhelning:takhelning",
    format: "image/png",
    transparent: true,
    maxZoom: 20,
    zoom: 20,
    apitoken: config.apikey,
    attribution: "Norkart © ",
  };

  function SetViewOnClick({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());
    return null;
  }

  return (
    <MapContainer
      center={latlng}
      zoomControl={true}
      zoom={20}
      maxZoom={20}
      scrollWheelZoom={true}
      style={{ color: "red" }}
      whenReady={(map) => {
        map.target.on("click", async function (e) {
          setlatlng(e.latlng);
          let result = await getMapCoordinates(e.latlng);
          let municipalityByLatlng = await getAddressFromLatLng(e.latlng);
          setCoordinates(result.newGeometri);
          dispatch(setCheckedAddress(result));
          dispatch(setMunicipality(municipalityByLatlng));
          window.sessionStorage.setItem(
            "checkedAddress",
            JSON.stringify(result)
          );
          window.sessionStorage.setItem("latlng", JSON.stringify(e.latlng));
          window.sessionStorage.setItem("roofID", 0);
          window.sessionStorage.setItem("roofName", null);
          setTimeout(() => {
            setIsDisabled(true);
            dispatch(setShow("step1"));
          }, "1000");
        });
      }}
    >
      <LayersControl position="bottomleft">
        <LayersControl.BaseLayer checked={"aerial"}>
          <TileLayer
            url={`https://waapi.webatlas.no/maptiles/tiles/webatlas-orto-newup/wa_grid/{z}/{x}/{y}.jpeg?APITOKEN=${config.apikey}`}
            attribution={`Norkart`}
            zoom={20}
            maxZoom={20}
          />
          <Polygon pathOptions={purpleOptions} positions={coordinates} />
          <WMSTileLayer url={config.WAAPItakflaterUrl} {...props} />
          <Marker position={markerLatlng} />
          <SetViewOnClick coords={latlng} />
        </LayersControl.BaseLayer>
      </LayersControl>
    </MapContainer>
  );
};

export default OrderMap;
