import RoofState from "./state";
import { SET_ROOF_INFO, SET_ROOF_INTEGRATED, SET_ROOF_TYPES } from "./types";

const Reducer = (state = RoofState, options) => {
  switch (options.type) {
    case SET_ROOF_TYPES:
      state.roofTypes = options.roofTypes;
      break;
    case SET_ROOF_INFO:
      state.roofInfo = options.roofInfo;
      break;
    case SET_ROOF_INTEGRATED:
      state.roofIntegrated = options.roofIntegrated;
      break;
    default:
      break;
  }

  return { ...state };
};

export default Reducer;
