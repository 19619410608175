import React from "react";

export const Buildingorangeicon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
    >
      <path
        d="M5 6.28125C5 6.55469 5.19531 6.75 5.46875 6.75H7.03125C7.26562 6.75 7.5 6.55469 7.5 6.28125V4.71875C7.5 4.48438 7.26562 4.25 7.03125 4.25H5.46875C5.19531 4.25 5 4.48438 5 4.71875V6.28125ZM10.4688 6.75H12.0312C12.2656 6.75 12.5 6.55469 12.5 6.28125V4.71875C12.5 4.48438 12.2656 4.25 12.0312 4.25H10.4688C10.1953 4.25 10 4.48438 10 4.71875V6.28125C10 6.55469 10.1953 6.75 10.4688 6.75ZM5.46875 10.5H7.03125C7.26562 10.5 7.5 10.3047 7.5 10.0312V8.46875C7.5 8.23438 7.26562 8 7.03125 8H5.46875C5.19531 8 5 8.23438 5 8.46875V10.0312C5 10.3047 5.19531 10.5 5.46875 10.5ZM10.4688 10.5H12.0312C12.2656 10.5 12.5 10.3047 12.5 10.0312V8.46875C12.5 8.23438 12.2656 8 12.0312 8H10.4688C10.1953 8 10 8.23438 10 8.46875V10.0312C10 10.3047 10.1953 10.5 10.4688 10.5ZM7.5 13.7812V12.2188C7.5 11.9844 7.26562 11.75 7.03125 11.75H5.46875C5.19531 11.75 5 11.9844 5 12.2188V13.7812C5 14.0547 5.19531 14.25 5.46875 14.25H7.03125C7.26562 14.25 7.5 14.0547 7.5 13.7812ZM10.4688 14.25H12.0312C12.2656 14.25 12.5 14.0547 12.5 13.7812V12.2188C12.5 11.9844 12.2656 11.75 12.0312 11.75H10.4688C10.1953 11.75 10 11.9844 10 12.2188V13.7812C10 14.0547 10.1953 14.25 10.4688 14.25ZM17.5 19.0938C17.5 18.8594 17.2656 18.625 17.0312 18.625H16.25V1.4375C16.25 0.929688 15.8203 0.5 15.3125 0.5H2.14844C1.64062 0.5 1.21094 0.929688 1.21094 1.4375V18.625H0.46875C0.195312 18.625 0 18.8594 0 19.0938V20.5H17.5V19.0938ZM3.08594 18.5859L3.125 2.375L14.375 2.41406V18.5859H10V15.9688C10 15.7344 9.76562 15.5 9.53125 15.5H7.96875C7.69531 15.5 7.5 15.7344 7.5 15.9688V18.5859H3.08594Z"
        fill="#F67736"
      />
    </svg>
  );
};

